/* For boncing loader css */
/* @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .animate-bounce {
    animation: bounce 1s infinite;
  }
  
  .delay-0 {
    animation-delay: 0s;
  }
  
  .delay-200 {
    animation-delay: 0.2s;
  }
  
  .delay-400 {
    animation-delay: 0.4s;
  } */
  


  /* For blinking loader css */
  @keyframes blink {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  
  .animate-dot-blink {
    animation: blink 1.4s infinite both;
  }
  
  .delay-0 {
    animation-delay: 0s;
  }
  
  .delay-200 {
    animation-delay: 0.2s;
  }
  
  .delay-400 {
    animation-delay: 0.4s;
  }
  