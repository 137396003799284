/* Bouncing loader animation */
/* @keyframes bounce {
  0%, 100% {
    transform: translateY(0);  
  }
  50% {
    transform: translateY(-10px);  
  }
}

.animate-dot-bounce {
  animation: bounce 0.6s infinite ease-in-out; 
}

.delay-0 {
  animation-delay: 0s;
}

.delay-200 {
  animation-delay: 0.2s;
}

.delay-400 {
  animation-delay: 0.4s;
} */


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Full page height */
  background-color: #f8f9fa; /* Optional background */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
